import React, { Component } from 'react';
import $ from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

class DataTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show_all: false,
        };

        this.toggle_show_all = this.toggle_show_all.bind(this);
    }

    toggle_show_all() {
        this.setState({ show_all: !this.state.show_all });
    }

    render() {
        let demo_rows = [];
        for (let demo of this.props.data) {
            demo_rows.push(
                <tr>
                    <td>{demo.demo}</td>
                    <td>{demo.itemsViewed}</td>
                    <td>{(demo.add_to_cart_rate * 100).toFixed(2)}%</td>
                    <td>${demo.opportunity_size.toFixed(0)}</td>
                </tr>,
            );

            if (demo_rows.length == 5 && !this.state.show_all) {
                break;
            }
        }
        if (demo_rows.length > 0) {
            let show_all = 'Show All';
            if (this.state.show_all) {
                show_all = 'Show Less';
            }
            return (
                <div class="col-6">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{this.props.demo_name}</th>
                                <th>Page Views (Last 30 days)</th>
                                <th>% Add To Cart</th>
                                <th>Opportunity Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {demo_rows}

                            <tr>
                                <td>
                                    <a
                                        onClick={this.toggle_show_all}
                                        style={{
                                            color: 'blue',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {show_all}
                                    </a>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default class MakeBetterOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            insight: { opportunity_size: 0 },
            supporting_data: {},
            loaded: false,
        };

        this.get_insight = this.get_insight.bind(this);
        this.load_insight = this.load_insight.bind(this);
    }

    componentDidMount() {
        this.get_insight();
    }

    get_insight() {
        let params = get_global_state()['params'];
        let url = '';
        if (params['source']) {
            url = `/api/get_insight/?type=make_better_offer&item_id=${params['item_id']}&source=${params['source']}&medium=${params['medium']}`;
        } else {
            url = `/api/get_insight/?type=make_better_offer&item_id=${params['item_id']}&age=${params['userAgeBracket']}&gender=${params['userGender']}&region=${params['region']}`;
        }

        ajax_wrapper('GET', url, {}, this.load_insight);
    }

    load_insight(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    render() {
        let support_tables = [];
        if (this.state.supporting_data.gender_list) {
            support_tables.push(
                <DataTable
                    data={this.state.supporting_data.gender_list}
                    demo_name="Gender"
                />,
            );
        }

        if (this.state.supporting_data.region_list) {
            support_tables.push(
                <DataTable
                    data={this.state.supporting_data.region_list}
                    demo_name="Region"
                />,
            );
        }

        if (this.state.supporting_data.age_list) {
            support_tables.push(
                <DataTable
                    data={this.state.supporting_data.age_list}
                    demo_name="Age Bracket"
                />,
            );
        }

        if (this.state.supporting_data.channel_list) {
            support_tables.push(
                <DataTable
                    data={this.state.supporting_data.channel_list}
                    demo_name="Traffic Source"
                />,
            );
        }

        let support_description = null;
        if (support_tables.length > 0) {
            support_description = (
                <h4>
                    Use the below data to understand who is coming and not
                    converting and how to make a better offer for them.
                </h4>
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <div class="col-12 d-flex flex-column gap-2">
                        <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                            <svg class="bi" width="1em" height="1em"></svg>
                        </div>
                        <h4 class="fw-semibold mb-0 text-body-emphasis">
                            <Button type="dark">Make A Better Offer</Button>
                        </h4>
                        <p class="text-body-secondary">
                            {this.state.insight.opportunity}
                        </p>
                        <p>
                            <b>Monthly Opportunity:</b> $
                            {this.state.insight.opportunity_size.toFixed(0)}
                        </p>
                    </div>

                    <div class="col-12">{support_tables}</div>

                    <h2>
                        How To Make A Better Offer And Increase Your Conversion
                        Rate
                    </h2>
                    <iframe
                        src="https://www.loom.com/embed/6dcb187daaa647b1ae800eb79f526c70?sid=4015c5be-f994-459c-af6f-c92eb5f3c1b0"
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        style={{
                            width: '600px',
                            height: '314px',
                        }}
                    ></iframe>
                    <p>The key to an offer is the following:</p>
                    <p>
                        First, identify the <b>dream outcome</b> for the
                        audience who is buying this item. What do they hope to
                        get out of it?
                    </p>
                    <p>
                        Secondly, how can you make it as{' '}
                        <b>quick and painless</b> to get that dream outcome?
                    </p>
                    <p>
                        For example, if you are selling a jacket, perhaps the
                        dream outcome is to be warm in -10 degree weather. And
                        all it takes is to put on this jacket, done.
                    </p>
                    <p>
                        Finally, build up a ton of <b>trust</b> that their
                        outcome will happen. User testimonials, videos of
                        someone in -10 degree weather, <b>a guarantee</b> that
                        they will be warm or they get their money back.
                    </p>

                    <p>
                        If you are doing all those things, perhaps you don't
                        understand the dream outcome. Reach out to the customers
                        who did buy and ask them why they bought your product
                        and then rewrite your offer.
                    </p>

                    <p>
                        And feel free to go deep into these ideas with{' '}
                        <a
                            href="https://www.acquisition.com/training/offers"
                            target="_blank"
                        >
                            this video series.
                        </a>
                    </p>

                    <Button
                        type="success"
                        href="https://calendly.com/jeremy-thiesen1/meetings"
                        target="_blank"
                    >
                        Schedule Time With Us To Help
                    </Button>
                </Container>
            </Loading>
        );
    }
}
